<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ title }}
        </h3>
        <div>
          <button class="btn btn-success btn-sm" @click="editCity()">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="cities"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.ar_name") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <td class="budget">
            {{ row.item.name }}
          </td>
          <td class="budget">
            {{ row.item.local_name }}
          </td>
          <td class="budget text-right">
            <button class="btn btn-success btn-sm" @click="editCity(row.item)">
              <i class="fas fa-edit"></i>
            </button>
            <!-- <router-link
              :to="`/couriers/${row.item.id}`"
              class="btn btn-danger btn-sm"
              ><i class="fas fa-trash"></i
            ></router-link> -->
          </td>
        </template>
      </base-table>
    </div>
    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
  <!-- City Modal -->
  <div
    v-if="editModal"
    class="modal fade show d-block"
    id="editModal"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editModalLabel">
            {{ $t("form.city_form") }}
          </h5>
          <button
            type="button"
            class="btn-secondary"
            @click="editModal = false"
          >
            x
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{
                  $t("form.en_name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.name_en"
                />
              </div>
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{
                  $t("form.ar_name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.name_ar"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="editModal = false"
          >
            {{ $t("form.close") }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="updateCity(cityDetails)"
          >
            {{ $t("form.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "cities-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      // Modals
      editModal: false,
      currentData: {},
      updatedData: {},
      cities: "",
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.getCities("admin/administration/cities/indexPaginate?page=1");
  },
  watch: {
    page: function () {
      this.getCities(
        "admin/administration/cities/indexPaginate?page=" + this.page
      );
    },
  },
  methods: {
    getCities: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.cities = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Edit City
    editCity(cityData) {
      this.updatedData = {};
      this.editModal = true;
      if (cityData) {
        this.currentData = cityData;
        this.updatedData.name_en = cityData.name;
        this.updatedData.name_ar = cityData.local_name;
        this.updatedData.id = cityData.id;
      }
    },
    // Create Or Update City
    updateCity: function () {
      this.loading = true;
      let formData = this.toFormData(this.updatedData);
      var url;
      if (this.updatedData.id) {
        url = "admin/administration/cities/update";
      } else {
        url = "admin/administration/cities/store";
      }
      axios
        .post(url, formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: response.data.message,
                showConfirmButton: false,
              });
            } else {
              this.cities.filter((item) => {
                if (this.updatedData.id === item.id) {
                  item.name = this.updatedData.name_en;
                  item.local_name = this.updatedData.name_ar;
                }
              });
              this.updatedData = {};
              this.editModal = false;
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Form Fields
    toFormData: function () {
      var fd = new FormData();
      if (this.updatedData["id"]) {
        fd.append("_method", "put");
        fd.append("id", this.updatedData["id"]);
      }
      fd.append("ar[name]", this.updatedData["name_ar"]);
      fd.append("en[name]", this.updatedData["name_en"]);
      return fd;
    },
  },
};
</script>
